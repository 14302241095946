<template>
  <b-modal
    size="md"
    id="competitor-edit"
    title="Edit Competitor"
    ok-title="Save"
    @ok="submit"
    @hidden="$emit('hidden')"
    :ok-disabled="isProcessing"
  >
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="name"
          >Competitor Name <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-input v-model="form.text" id="text"></b-form-input>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="color"
          >Competitor Color <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-input
          type="color"
          v-model="form.color"
          id="color"
        ></b-form-input>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import { CompetitorService } from "@/core/services"

export default {
  name: "CompetitorEdit",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        text: null,
        value: null,
        color: "#000000",
      },
      isProcessing: false,
    }
  },
  validations: {
    form: {
      text: { required },
      color: { required },
    },
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.item))
  },
  // updated() {
  //   alert()
  //   this.form = Object.assign({}, this.item)
  // },
  methods: {
    async submit(e) {
      e.preventDefault()
      this.isProcessing = true
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.isProcessing = false
        return
      }
      let data = Object.assign({}, this.form)
      data.value = data.text
      data.cusId = this.$route.params.cusId
      let response = await CompetitorService.update(data)
      if (!response.error) {
        this.$emit("success")
        this.$root.$bvToast.toast("Update competitor success", {
          title: `Success`,
          variant: "success",
          solid: true,
        })
        this.$root.$bvModal.hide("competitor-edit")
      }
      this.isProcessing = false
    },
  },
}
</script>

<style></style>
