<template>
  <div>
    <div class="row mt-5">
      <div class="col-12">
        <KTCard>
          <template v-slot:title>
            <h6 class="mb-0">{{ brand ? brand.name : "" }} - Competitors</h6>
          </template>
          <template v-slot:toolbar>
            <button-add v-b-modal.competitor-create></button-add>
          </template>
          <template v-slot:body>
            <b-table
              bordered
              responsive
              :items="competitors"
              :fields="fields"
              show-empty
            >
              <template v-slot:empty>
                <center>No data</center>
              </template>

              <template v-slot:cell(no)="row">
                {{ iteration + row.index + 1 }}
              </template>

              <template v-slot:cell(color)="row">
                <div class="d-flex flex-row align-items-end">
                  <div
                    :style="{
                      'background-color': row.item.color,
                      height: '25px',
                      width: '25px'
                    }"
                  ></div>
                  <span class="ml-2">{{ row.item.color }}</span>
                </div>
              </template>

              <template v-slot:cell(actions)="row">
                <button-edit
                  v-b-modal.competitor-edit
                  @click="selectCompetitor(row.item)"
                >
                </button-edit>

                <button-delete
                  v-b-modal.delete-competitor
                  @click="selectCompetitor(row.item)"
                >
                </button-delete>
              </template>
            </b-table>
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <span v-if="paging"
                >{{ paging.totalRows | number_format }} records</span
              >
              <b-pagination-nav
                v-if="paging.numberOfPages"
                v-model="paging.currentPage"
                :total-rows="paging.totalRows"
                align="right"
                :link-gen="linkGen"
                :number-of-pages="paging.numberOfPages"
                use-router
                :per-page="paging.perPage"
                @change="changePage"
              ></b-pagination-nav>
            </div>
          </template>
        </KTCard>
      </div>
    </div>
    <competitor-create @success="getCompetitors"></competitor-create>
    <competitor-edit
      v-if="selectedCompetitor"
      @success="getCompetitors"
      @hidden="selectedCompetitor = null"
      :item="selectedCompetitor"
    ></competitor-edit>
    <confirm-delete
      v-if="selectedCompetitor"
      modal-id="delete-competitor"
      :remove-fn="removeCompetitor"
      @ok="getCompetitors"
    ></confirm-delete>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { CompetitorService } from "@/core/services"
import CompetitorCreate from "./CompetitorCreate"
import CompetitorEdit from "./CompetitorEdit"
import KTCard from "@/view/content/Card.vue"
import { CustomerService } from "../../../core/services"
export default {
  name: "Competitor",
  components: {
    CompetitorCreate,
    CompetitorEdit,
    KTCard
  },
  data() {
    return {
      paging: {
        perPage: 10,
        currentPage: 1,
        totalRows: 0,
        numberOfPages: 0
      },

      selectedCompetitor: null,
      competitors: [],
      brand: null,
      fields: [
        {
          key: "no",
          label: "#",
          class: "text-center"
        },
        {
          key: "text",
          label: "Competitor Name"
        },
        {
          key: "color",
          label: "Competitor Color"
        },
        {
          key: "actions",
          label: "Action",
          class: "text-center"
        }
      ]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Brands", route: "/brands" },
      { title: "Competitors" }
    ])
    this.getCustomer()
    this.paging.currentPage = Number(this.$route.query.page) || 1
    this.getCompetitors()
  },
  computed: {
    iteration() {
      return this.paging.perPage * (this.paging.currentPage - 1)
    }
  },
  methods: {
    changePage(page) {
      this.paging.currentPage = page
      this.getCompetitors()
    },
    async getCustomer() {
      return CustomerService.getById(this.$route.params.cusId).then(brand => {
        this.brand = brand
      })
    },
    async getCompetitors() {
      return CompetitorService.get(this.$route.params.cusId, {
        offset: this.paging.perPage * (this.paging.currentPage - 1),
        limit: this.paging.perPage
      }).then(response => {
        this.competitors = response.results
        this.paging.totalRows = response.total
        this.paging.numberOfPages = Math.ceil(
          this.paging.totalRows / this.paging.perPage
        )
      })
    },
    removeCompetitor() {
      return CompetitorService.delete(this.selectedCompetitor.competitorId)
    },
    selectCompetitor(competitor) {
      this.selectedCompetitor = Object.assign({}, competitor)
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`
    }
  }
}
</script>
